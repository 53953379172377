
import { Switch, Route,useHistory, useLocation } from "react-router-dom";

import Call from "./pages/Call/";
import RecebeLigacaoVoipModal from "./components/Voip/RecebeLigacaoVoipModal";
import Logout from "./pages/Logout";
import GuLogin from "./pages/GuLogin";
import React,{ useState,useCallback,useContext,useEffect, Suspense} from "react";
import caAPI from "./services/caAPI";
import { AtendimentoContext } from "./contexts/AtendimentoContext";
import { trackPromise } from "react-promise-tracker";
import { AtendentesContext } from "./contexts/AtendentesContext";

export default function Routes() {
  const [tokenValido, setTokenValido] = useState(false);
  const {carregaConfiguracoesDoUsuario} = useContext(AtendimentoContext);
  const {recarregarListaAtendentes} = useContext(AtendentesContext)
  const history = useHistory();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);

  const valida = useCallback(async function() {
    let tokenUrl = queryParams && JSON.parse(JSON.parse(queryParams.get('token')));
    if(tokenUrl){
      caAPI.setLocalState('token', tokenUrl);
    }
    let tokenAtual = caAPI.getLocalState("token");
    if (
      caAPI.getLocalState("token") !== null
    ) {
      caAPI
        .validaToken(tokenAtual)
        .then(async (res) => {
          const valido = res?.data?.valido || false;
          if(valido){
            caAPI.refreshApi();
            recarregarListaAtendentes()
            caAPI.setLocalState("permissoes", res.data.permissoes);
            let filtrosFixados = await carregaConfiguracoesDoUsuario()
            const filtrosInicial = {data_inicio: new Date(), data_fim: new Date()};
            caAPI.setLocalState('filtros', filtrosFixados ? filtrosFixados : JSON.stringify(filtrosInicial));
            setTokenValido(valido);
          }else{
            setTokenValido(valido);
            throw "Acesso não autorizado";
          }
        })
        .catch((err) => {
          console.log(err);
          caAPI.logout();
        });
    } else {
      caAPI.logout();
    }
  }, []);

  useEffect(() => {
    if (!history.location.pathname.includes("gu-login")) {
      trackPromise(valida());
    }
  }, [history.location.pathname, valida]);

  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <Switch>
        <Route path="/gu-login/:token" component={GuLogin} />
        {tokenValido ? (
          <>
             <Route path="/" component={Call}/>
          </>
        ): (
          
            <Route path="/logout" component={Logout}/>
           
        )}
      </Switch>
    </Suspense>
  );
}
