import {Button,Row,Col} from 'react-bootstrap';
import './css/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH, faClose, faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect } from 'react';
import { VoipContext } from './../../contexts/VoipContext.js';
import { AtendimentoContext } from '../../contexts/AtendimentoContext';
import usuarioIcone from './../../assets/img/usuario.svg'
import { useState } from 'react';
import caAPI from '../../services/caAPI';
import { infoToast } from "../../components/DommusToast";
import DommusModal from '../DommusModal';
import { AtendentesContext } from '../../contexts/AtendentesContext';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom'

export const VoipModal = (props) => {
  const { atendimentoAtivo } = useContext(AtendimentoContext)
  const {listaAtendentes} = useContext(AtendentesContext);
  const { inCallStatus, handleCall, receiveCall } = useContext(VoipContext);
  const [modalDommus,setModalDommus] = useState(false);
  const [idTimeoutTransferenciaPerdida, setIdTimeoutTransferenciaPerdida] = useState(null);
  const [cacheCallSidTransferencia, setCacheCallSidTransferencia] = useState(null);
  const location = useLocation()

  const transferirLigacao = () => {

     if(!inCallStatus){
        handleCall()
     }
     setModalDommus(true)
    
  }

  const callbackTransferenciaPerdida = (data) =>{
    if(cacheCallSidTransferencia && (Number(data.id_atendimento) === atendimentoAtivo.id) ){
      if(idTimeoutTransferenciaPerdida){
        clearTimeout(idTimeoutTransferenciaPerdida);
        setIdTimeoutTransferenciaPerdida(null)
      }
      setModalDommus(false);
      setCacheCallSidTransferencia(null)
      infoToast.fire({text: 'Chamada não atendida no destino'});
    }
  };

  function handleTransfer(e) {

    setModalDommus(false);
    
      const data = {atendente: e.target.value}
      let conteudoAtual = Array.isArray(atendimentoAtivo.conteudos) && [...atendimentoAtivo.conteudos].pop();
      let detalhes = conteudoAtual && JSON.parse(conteudoAtual?.detalhes);
     
      setCacheCallSidTransferencia(detalhes?.CallSid);
      caAPI.atendimento.update(data, atendimentoAtivo.id).then(res => {
        const timeoutTransferenciaPerdida = setTimeout(() => {
          caAPI.atendimento.transferenciaNaoAtendida(atendimentoAtivo.id, Number(caAPI.getLocalState("idUsuario")))
            .then(() => {
              callbackTransferenciaPerdida({id_atendimento: atendimentoAtivo.id});
            })
            .catch(error => console.log(error));
        }, ((Number(caAPI.getLocalState('tempoLimiteVoipSegundos') || 30) * 1000) + 10000));
        setIdTimeoutTransferenciaPerdida(timeoutTransferenciaPerdida);
        infoToast.fire({text: 'Transferindo...'});
      }).catch((err) => {
       
        Swal.fire({
            titleText: "Erro",
            text: "Erro ao editar atendimento",
            icon: 'error'
        });
      });
    }
    
    const atenderLigacao = () => {
      const queryParams = new URLSearchParams(location.search);
      const dadosNovaChamada = JSON.parse(queryParams.get('dadosChamada'));
      if(dadosNovaChamada?.connection_socket && dadosNovaChamada?.id) {
        receiveCall(dadosNovaChamada.connection_socket, dadosNovaChamada.id)
      }else{
        handleCall();
      }
    }

    return(
        <>
          <div className='p-3 voip-box'>
         
            <div className='mt-3 voip-close-row'>
                <div>
                  <span className='text-voip'>Voip</span>
                </div>
                <div>
                <FontAwesomeIcon className='text-voip' icon={faClose} color='#FFF' onClick={() => window.ReactNativeWebView.postMessage('close')} />
                </div>
            </div>
            <Row className='my-5'>
                <Col md={12}  className={'d-flex justify-content-center'}>
                   <img className='phone-icon' src={usuarioIcone} alt="" />
                </Col>
            </Row>
            <Row className='my-5'>
                <Col md={12}  className={'d-flex justify-content-center'}>
                   <span className='phone-number-modal'>+{props.contatoDestino ?? props.atendimento.contato_destino.trim()}</span>
                </Col>
                <Col md={12}  className={'d-flex justify-content-center'}>
                 {(props.atendimento && props.atendimento.nome) &&  <span className='phone-number-modal'>Ligar para {props.atendimento.nome}</span>}
                </Col>
            </Row>
            <div  className='phone-btn-container'>
                <div>
                  <Button variant='success' className='btn-redonly' onClick={() => atenderLigacao()}>
                    <FontAwesomeIcon color='#FFF' icon={faPhone}/>
                  </Button>
                </div>
                <div>
                  
                  <Button onClick={() => {
                    window.ReactNativeWebView.postMessage('close')
                      props.setShowVoipModal(false)
                  }} className='btn-redonly' variant='danger'>
                          <FontAwesomeIcon color='#FFF' icon={faPhoneSlash}/>
                  </Button>
                </div>
                <div>
               
                     <Button variant='warning' className='btn-redonly' onClick={transferirLigacao}>
                        <FontAwesomeIcon color='#FFF' icon={faArrowsAltH}/>
                    </Button>
                  
                </div>
            </div>
        </div>
            <DommusModal open={ modalDommus} close={setModalDommus}>
                    <select className='form-control' onChange={handleTransfer}>
                        <option value={''}>Selecione o atendente</option>
                        {listaAtendentes.map((item,index) => (
                          <option key={index} value={item.id}>{item.nome}</option>
                        ))}
                    </select>
            </DommusModal>
        </>
    );
}