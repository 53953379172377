import React, { createContext, useCallback, useContext, useState, useEffect } from "react";
import { Device } from "twilio-client";
import caAPI from "../services/caAPI";
import Swal from "sweetalert2";
import { AtendimentoContext } from "./AtendimentoContext";
import { useStopwatch } from 'react-timer-hook';
import { trackPromise } from "react-promise-tracker";

export const VoipContext = createContext({});
export function VoipProvider({ children }) {
  const [inCallStatus, setInCallStatus] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [connection, setConnection] = useState(false);
  const [triggerCloseModalIncoming, setTriggerCloseModalIncoming] = useState(null);
  const { atendimentoAtivo, setAtendimentoAtivo, handleAdiconaLista } = useContext(AtendimentoContext);
  const [twilioCallClient, setTwilioCallClient] = useState(null)
  const [twilioRespondeClient, setTwilioRespondeClient] = useState(null)
  const [direcao, setDirecao] = useState(null);
  const [chamadasPerdidas, setChamadasPerdidas] = useState([]);

  const {
      seconds,
      minutes,
      isRunning,
      start,
      pause,
      reset,
    } = useStopwatch({ autoStart: false });

  const handleCloseModalIncoming = () => {
    const trigger = Math.random();
    return setTriggerCloseModalIncoming(trigger);
  };

  const registrarContador = () =>{
    pause();
    if(atendimentoAtivo){
      caAPI.atendimento.getMessages(atendimentoAtivo.id, 1).then((res) => {
        if(res.data && res.data.conteudos){
          const novoAtendimento = Object.assign({}, atendimentoAtivo);
          novoAtendimento.conteudos = res.data.conteudos
          setAtendimentoAtivo(novoAtendimento);
        }
      }).catch(error => console.log(error));
    }
    reset(null, false);

  };

  const clienteTwilioFactory = useCallback((idAtendimentoRecebido = null) => {
    const device = new Device();
    device.on('disconnect', function () {
      registrarContador();
      handleCloseModalIncoming();
      setInCallStatus(false);
      setConnection(false);
      setIsMuted(false);
      device.destroy();
    });
    device.on('cancel', function () {
      registrarContador();
      handleCloseModalIncoming();
      setInCallStatus(false);
      setConnection(false);
      setIsMuted(false);
      device.destroy();
    });
    device.on('incoming', function (conn) {
      setConnection(conn);
    });
    device.on('connect', function (conn) {
      if(conn.customParameters.get('direcao') === 'enviado'){
        caAPI.atendimento.voip.novoIniciarChamada(atendimentoAtivo.id, conn.parameters.CallSid).then(res => {
          const novoAtendimentoAtivo = Object.assign({}, atendimentoAtivo);
          novoAtendimentoAtivo.conteudos.push(res.data);
          setAtendimentoAtivo(novoAtendimentoAtivo);
        }).catch(err => {
          console.error(err)
        })
      }else if(idAtendimentoRecebido){
        let atendenteId = { atendente: caAPI.getLocalState('idUsuario') }
        trackPromise(
          caAPI.atendimento.atender(atendenteId, idAtendimentoRecebido).then(res => {
            const novoAtendimentoAtivo = Object.assign({}, res.data);
            setAtendimentoAtivo(novoAtendimentoAtivo);
          }).catch(err => {
            Swal.fire({
              titleText: "Erro",
              text: "Houve um problema ao atribuir o atendente  " + err,
              icon: 'error'
            });
          })
        );
      }
      setConnection(conn);
      setInCallStatus(true);
      start();
    });
    device.error(function (error) {
      console.log("ERROR Twilio: " + error.message);
    });

    return device;
  }, [atendimentoAtivo, handleAdiconaLista, setAtendimentoAtivo]);

  useEffect(()=>{
    if(!(connection) || (connection.customParameters.get('direcao') !== 'enviado')){
      const twilioDevice = clienteTwilioFactory();
      setTwilioCallClient(twilioDevice);
    }
  }, [atendimentoAtivo])

  const receiveCall = useCallback((connection_socket, idAtendimento) => {
    const twilioDevice = clienteTwilioFactory(idAtendimento);
    if (process.env.REACT_APP_ENV === 'production') {
      twilioDevice.setup(connection_socket, {
        debug: true,
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });
      twilioDevice._enabledSounds[Device.SoundName.Incoming] = false;
    }
    setTwilioRespondeClient(twilioDevice)
  }, [clienteTwilioFactory]);

  const readyCallback = useCallback(() => {

    if(atendimentoAtivo){
      const dadosVoip = {
        id_atendimento: atendimentoAtivo.id,
        id_cliente: caAPI.getLocalState('idCliente'),
        contato_externo: atendimentoAtivo.contato_destino.trim(),
        contato_interno: atendimentoAtivo.contato_origem.trim(),
        direcao: 'enviado'
      };


      twilioCallClient.connect(dadosVoip);
    }
  }, [atendimentoAtivo, twilioCallClient]);

  const handleCall = () => {
    caAPI.getTokenCcd().then(res => {
      twilioCallClient.setup(res.data, {
        debug: true,
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });
      setInCallStatus(true);
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar o token da chamada " + err,
        icon: 'error'
      });
    })
    twilioCallClient.ready(() => {
      readyCallback();
    });
    twilioCallClient.error(function (error) {
      console.log("ERROR Twilio: " + error.message);
    });
  };

  function hangUp() {
    if(twilioRespondeClient && twilioRespondeClient.activeConnection()){
      twilioRespondeClient.disconnectAll();
    }
    if(twilioCallClient && twilioCallClient.activeConnection()){
      twilioCallClient.disconnectAll();
    }
    setInCallStatus(false);
  }


  function handleMuteCall() {
    if(twilioRespondeClient && twilioRespondeClient.activeConnection()){
      twilioRespondeClient.activeConnection().mute(!isMuted);
    }
    if(twilioCallClient && twilioCallClient.activeConnection()){
      twilioCallClient.activeConnection().mute(!isMuted);
    }
    setIsMuted(value => !value);
  }

  function handleRejeitar() {
    if (connection) {
      connection.reject();
    }
    if(twilioRespondeClient && twilioRespondeClient.activeConnection()){
      const conexao = twilioRespondeClient.activeConnection();
      conexao.reject()
      twilioRespondeClient.disconnectAll();
    }
    if(twilioCallClient && twilioCallClient.activeConnection()){
      twilioCallClient.disconnectAll();
    }
  }

  function answerCall() {
    setInCallStatus(true);
    connection.accept();
  }

  function adicionaChamadaPerdida (chamada = {}) {
    setChamadasPerdidas((valorAnterior) => {
      return [...valorAnterior, chamada]
    })
  }

  return (
    <VoipContext.Provider value={{
      receiveCall,
      inCallStatus,
      handleCall,
      hangUp,
      handleMuteCall,
      isMuted,
      setIsMuted,
      answerCall,
      handleRejeitar,
      triggerCloseModalIncoming,
      connection,
      twilioRespondeClient,
      minutes,
      seconds,
      adicionaChamadaPerdida,
      chamadasPerdidas
    }}>
      {children}
    </VoipContext.Provider>
  )
}
